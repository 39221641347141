import React from 'react'
import { Button, Container } from '@roar/components'
import { Flex } from 'rebass/styled-components'
import { Link } from 'gatsby'

export const Pagination = ({ pageNumber, numberOfPages, nextPagePath, previousPagePath }) => {
  // const pageNumbers = Array(numberOfPages)
  //   .fill()
  //   .map((_, i) => i + 1)

  return (
    <div>
      <Container>
        <Flex justifyContent="space-between">
          {pageNumber !== 0 ? (
            <Button as={Link} to={previousPagePath}>
              <Flex alignItems="center">
                <i className="icon icon-down paginate-left" aria-hidden="true"></i>
                Previous
              </Flex>
            </Button>
          ) : (
            <Flex />
          )}
          {pageNumber + 1 !== numberOfPages && (
            <Button as={Link} to={nextPagePath}>
              <Flex alignItems="center">
                Next
                <i className="icon icon-down paginate-right" aria-hidden="true"></i>
              </Flex>
            </Button>
          )}
        </Flex>
      </Container>
    </div>
  )
}
