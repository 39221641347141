import React, { useState } from 'react'
import {
  Button,
  CategoryHeading,
  Row,
  Column,
  Card,
  Text,
  Heading,
  Animated,
  getAnimationVariant,
  theme,
} from '@roar/components'
import { Box, Flex } from 'rebass/styled-components'
import { mapperWithFunction } from '../common/utils'
import { Link } from 'gatsby'
import { formatImage } from './ImageWrapper'
import { Pagination } from '../components/Paginate'
import { LANGUAGE } from '../common/constants'
import { connectSearchBox, Configure } from 'react-instantsearch-dom'
import styled from 'styled-components'
import SearchWrapper, { SearchInput } from './SearchWrapper'
import { useMenu } from '../data/hooks/menu'
import { MENU_NAMES } from '../common/constants'

const RELATED_ITEMS_MAP = {
  date: ['date'],
  imageLogo: (value) => formatImage(value, true, 'imageLogo'),
  image: (value) => formatImage(value, true),
  headline: ['name'],
  postType: ['postType', 'name'],
  focalPoint: ['hotspot', 'focalPoint'],
  description: ['description', 'childMarkdownRemark', 'excerpt'],
  path: (value) => {
    let path = ''
    if (value.postType) {
      path += `/${value.postType.name.toLowerCase()}`
    }
    if (value.category && value.category.length) {
      path += `/${value.category[0].slug}`
    }
    return path + `/${value.year}/${value.month}/${value.slug}`
  },
}

const StyledMenuItem = styled(Flex)`
  flex: 0 0 auto;
  position: relative;
  @media (min-width: ${theme.breakpoints[3]}) {
    &::before {
      content: '';
      width: 1px;
      position: absolute;
      left: -${theme.space[2]}px;
      background-color: ${theme.colors.textDark};
      height: 1rem;
    }
  }
`
const BlogsWrapper = (props) => {
  const {
    blogs = [],
    title,
    description,
    showPagination = true,
    isSearchable = true,
    isRelatedPosts = false,
    pageContext: { categories, category, slug, postType, pagePath: path, ...pageContext } = {},
  } = props

  const [query, setQuery] = useState(``)
  const [isActive, setActive] = useState(false)

  // This line removes HTML tags from the title string using a regex expression.
  // The regex /(<([^>]+)>)/gi matches any string that starts with "<" and ends with ">" (HTML tags).
  // The replace() function then replaces these matches with an empty string '', effectively removing the HTML tags.
  const stringHeadline = title && title.replace(/(<([^>]+)>)/gi, '')

  // This menu must be set up in the CMS with a location added
  function selectMenu() {
    switch (postType) {
      case 'Blog':
        return MENU_NAMES.BLOG
      case 'Video':
        return MENU_NAMES.VIDEO
      case 'Press':
        return MENU_NAMES.PRESS
      default:
        return MENU_NAMES.BLOG
    }
  }

  let menuSelector = selectMenu(postType)
  const { items } = useMenu(menuSelector)

  const categoryItems =
    items?.map((i, index) => {
      return {
        label: i.menuLabel,
        slug: i.slug,
      }
    }) || []

  return (
    <Box bg="brandBeigeLight" pb={[4, 15]} px={[2, 14]}>
      <CategoryHeading
        eyebrow={
          category ? (
            <Flex alignItems="center" justifyContent={'center'}>
              <Link to={`/${path}/`}>
                <Text
                  color={theme.colors.textDark}
                  size="small"
                  letterSpacing={theme.letterSpacing.small}
                  style={{ textTransform: 'uppercase' }}
                >
                  {postType}
                </Text>
              </Link>
              <Box mx={[1]}>
                <i className={`icon icon-arrow-right`} />
              </Box>
              <Text
                color={theme.colors.brandRed}
                size="small"
                letterSpacing={theme.letterSpacing.small}
                style={{ textTransform: 'uppercase' }}
              >
                {category}
              </Text>
            </Flex>
          ) : null
        }
        headline={title}
        a
        description={description}
        headingAs={isRelatedPosts ? 'span' : 'h1'}
      />

      {isSearchable ? (
        <Flex
          flexDirection={['column', 'column', 'column', 'column', 'row']}
          justifyContent="space-between"
          alignItems={['center']}
          mb={[2]}
        >
          <Flex
            flexWrap={'wrap'}
            alignItems={['center']}
            flexDirection={['column', 'column', 'column', 'column', 'row']}
          >
            {categories ? (
              <>
                <Box flex="0 0 auto" mr={[0, 0, 0, 0, 2]}>
                  <Link to={`/${path}`}>
                    <Button
                      variant="text"
                      hasUnderline={!slug}
                      colorScheme={!slug ? 'red' : 'black'}
                      mb={!slug ? 0 : 1}
                    >
                      {'All'}
                    </Button>
                  </Link>
                </Box>
                {categoryItems.map((cat, index) => {
                  return (
                    <StyledMenuItem className="StyledMenuItem" mx={[2]} mt={[1, 2, 2, 2, 0]} key={index}>
                      <Link to={cat && `/${path}/${cat.slug}`}>
                        <Button
                          variant="text"
                          hasUnderline={slug === cat.slug}
                          colorScheme={slug === cat.slug ? 'red' : 'black'}
                          mb={slug === cat.slug ? 0 : 1}
                        >
                          <span key={index}>{cat.label}</span>
                        </Button>
                      </Link>
                    </StyledMenuItem>
                  )
                })}
              </>
            ) : null}
          </Flex>
          <Box mb={[2, 2, 2, 2, 1]} mt={[1, 2, 2, 2, 0]}>
            <SearchWrapper onSetQuery={setQuery}>
              <Configure filters={`postType:"${postType}"`} hitsPerPage={9} />
              <CustomSearchFooter onSetActive={setActive} active={isActive} placeholder={`Search ${stringHeadline}`} />
            </SearchWrapper>
          </Box>
        </Flex>
      ) : null}
      <Box>
        <Row justifyContent="flex-start">
          {blogs && blogs.length ? (
            blogs.map((b, idx) => {
              const {
                date = '',
                image = null,
                imageLogo = null,
                focalPoint,
                headline = '',
                description = '',
                path,
              } = mapperWithFunction(b, RELATED_ITEMS_MAP)
              return (
                <Column width={[1, 1, 6 / 12, 4 / 12]} mb={[2, 4]} flex="0 1 auto">
                  <Link to={path}>
                    <Animated shouldAnimate={true} variants={getAnimationVariant(idx)}>
                      <Card
                        alignment="left"
                        variant={imageLogo ? 'press' : 'standard'}
                        image={image}
                        logo={imageLogo}
                        focalPoint={focalPoint}
                        meta={
                          date
                            ? () => (
                                <Heading as="span" size="h7">
                                  {date}
                                </Heading>
                              )
                            : null
                        }
                        headline={headline}
                        description={<Text>{description}</Text>}
                      />
                    </Animated>
                  </Link>
                </Column>
              )
            })
          ) : (
            <Column width={[1, 1, 6 / 12, 4 / 12]} mb={[2, 4]} flex="0 1 auto">
              <Heading size="h4">{LANGUAGE.no_posts}</Heading>
            </Column>
          )}
        </Row>
      </Box>
      {showPagination && <Pagination {...pageContext} />}
    </Box>
  )
}

const CustomSearchFooter = connectSearchBox(SearchInput)

export default BlogsWrapper
